import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import PageHeader from '../components/PageHeader'
import Container from '../components/Container'
import Sidebar from '../components/Sidebar'
import List from '../components/List'
import Hours from '../components/Hours'
import Button, { ButtonGroup } from '../components/Button'
import parse from 'html-react-parser';
import { MediaPreFooter } from "../components/PreFooter"


const ServiceTemplate = ({data}) => {

    const htmlContent = data.servicesJson.htmlContent

    return (

	<Layout>
    {/* <Seo
      title={data.servicesJson.name}
    /> */}
    <PageHeader

      pageTitle={data.servicesJson.altName ? data.servicesJson.altName : data.servicesJson.name}
      description={data.servicesJson.altCaption ? data.servicesJson.altCaption : data.servicesJson.caption}
      coverImage={data.servicesJson.image.src}
      containTitle={false}
    >
    <List className="mt-5">
      {data.servicesJson.pointList.map((point, index) => {

        return <List.ListItem key={index}>{point}</List.ListItem>
      })}
    </List>
    </PageHeader>
    <Container size="medium" className={`mb-20`}>
    {/*data.servicesJson.shortDesc &&
        <div
        dangerouslySetInnerHTML={{__html: data.servicesJson.shortDesc}}
        />
    */}

    {/* {data.servicesJson.otherContent &&
        parse(data.servicesJson.otherContent)
    } */}

    {htmlContent &&
      <div className="mt-12 flex flex-row justify-center items-start">
      <div className="flex-trueauto md:pr-20 lg:pr-28 xl:pr-32">
      {data.servicesJson.bodyHeader &&
        <h2
        dangerouslySetInnerHTML={{__html: data.servicesJson.bodyHeader}}
        className={`uppercase`}
        />
      }
      {htmlContent.map((html,k) => {
          return (
            <React.Fragment 
  
              key={k}
            >
              {parse(html)}
            </React.Fragment>
          )
        })}

      </div>

      <Sidebar includeBtn={true} className={`lg:-mt-[15%] xl:-mt-[20%] z-10`}>
        <Sidebar.Module
        header={`${data.servicesJson.sidebarHeader ? data.servicesJson.sidebarHeader : data.servicesJson.name}`}
        className="text-secondary-default"
        >

        <List className="text-xxs text-white" style={{paddingLeft:'0', paddingTop: '0.5rem'}}>


          {data.servicesJson.keyServices &&
              data.servicesJson.keyServices.slice(0,5).map((listItem, key) => {

                  return (
                      <List.ListItem key={key} styleType="arrow-right" iconSize="small" iconColor={`rgba(255,255,255,0.35)`} className="text-white" style={{lineHeight: '22px'}}>{listItem}</List.ListItem>
                  )
              })
          }
        </List>
        </Sidebar.Module>
        <Sidebar.Module
        header={`Hours of Operation`}
        className="text-secondary-default"
        >
        <Hours className="text-xs mt-3 text-white leading-7" shortForm={true} contained={false} />
        </Sidebar.Module>
        <Sidebar.Module className={`text-center`}>
            <Button to={`/contact`} variation="secondary" className="-mt-1">Request a Visit</Button>
        </Sidebar.Module>
      </Sidebar>
      </div>
    }
    </Container>
    <MediaPreFooter containerSize={`flush`} bgClassName={`bg-accent-lighter`}>
      <ButtonGroup>
        <Button to={`/contact`} variation="secondary">Contact Steve</Button>
        <Button to={`/services`} variation="outline" invert={false} className={`sm:inline-block hidden`}>All Services</Button>
      </ButtonGroup>
    </MediaPreFooter>
	</Layout>


)}

export const Head = ( {data} ) => <Seo2 title={data.servicesJson.name} description={data.servicesJson.metaDesc} />

export default ServiceTemplate


export const query = graphql`
  query($id: String) {
	  servicesJson(id: { eq: $id }) {
      name
      altName
      id
      caption
      altCaption
      image {
        src {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3
              layout: FULL_WIDTH
            )
          }
        }
	    name
      }
      metaDesc
      sidebarHeader
      pointList
      shortDesc
      bodyHeader
      htmlContent
      keyServices
      otherContent
    }
  }
`
