import React from 'react'
import Container from './Container'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import useWindowSize from '../utils/useWindowSize'

const PreFooter = ( {header, content, bgClass, flush, children, ...rest} ) => {

	let containerSize = null;
	if (flush === true) containerSize = 'flush';

	return (
		<section className="prefooter w-full">

			<div {...rest}>
			<Container size={containerSize}>
				<div className={`p-10 sm:p-20 md:px-28 lg:px-36 ${flush === true ? 'shadow-none' : 'shadow-sm'} ${bgClass}`}>
					<h2
					dangerouslySetInnerHTML={{__html: header}}
					className={`md:max-w-[75%] lg:max-w-[50%] md:mx-auto`}
					/>
					{content &&
						<p
						dangerouslySetInnerHTML={{__html: content}}
						/>
					}
					{children &&
						<div className={`mt-3 md:mt-5 lg:mt-10`}>{children}</div>
					}
				</div>
			</Container>

			</div>


		</section>
	)
}

PreFooter.propTypes = {
	header: PropTypes.string,
	content: PropTypes.string,
	bgClass: PropTypes.string,
	children: PropTypes.node,
	flush: PropTypes.bool
}

PreFooter.defaultProps = {
	header: `Your dog’s home away from home — since 2016`,
	content: ``,
	bgClass: `bg-accent-lighter`,
	flush: false
}

export default PreFooter

export const MediaPreFooter = props => {
	const {header, content, bgClassName, image, children} = props;

	const size = useWindowSize();

	let getMh = Math.floor(size.width * 0.4);
	if (1023 >= size.width) getMh = Math.floor(size.width * 0.5);
	if (767 >= size.width) getMh = 'auto';

	

	const prefooterStyle = {
		minHeight: isNaN(getMh) ? 'auto' : getMh,
		backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23000000' fill-opacity='0.075' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
		backgroundBlendMode: `normal`,
		backgroundSize: '5.5px'
	}

	return (
		<Container size={props.containerSize ? props.containerSize : null}>
			<section className={`${bgClassName} prefooter w-full rounded-sm shadow-sm overflow-hidden lg:h-[40vw] md:h-[50vw] h-auto`} style={prefooterStyle}>
				
					<div className={`flex flex-row items-center md:flex-nowrap flex-wrap h-full`}>
						<div className={`md:flex-1/2 md:max-w-1/2 max-w-full flex-[100%] relative h-full md:min-h-[100%] min-h-[20rem] block`}>
							<div className={`absolute w-full h-full overflow-hidden block`}>
							{image === 'v2' ? (
								<StaticImage

								src={`../images/business/casak9-1031.jpg`}
								alt="Casa Canine"
								className={`!w-full !h-full`}
								quality={70}
								/>
							) : (
								<StaticImage

								src={`../images/business/casak9-6107.jpg`}
								alt="Casa Canine"
								className={`!w-full !h-full`}
								quality={70}
								/>
							)}
							</div>
						</div>
						<div className={`md:flex-1/2 md:max-w-1/2 max-w-full flex-[100%] md:p-7 p-14 text-center`}>
						<h2
							dangerouslySetInnerHTML={{__html: header}}
							className={`md:max-w-[80%] lg:max-w-[60%] md:mx-auto`}
							/>
							{content &&
								<p
								dangerouslySetInnerHTML={{__html: content}}
								/>
							}
							{children &&
								<div className={`mt-3 md:mt-5 lg:mt-10`}>{children}</div>
							}
						</div>
					</div>
				

			</section>
		</Container>
	)
}

MediaPreFooter.defaultProps = {
	header: `Your dog’s home away from home — since 2016`,
	content: ``,
	bgClassName: 'bg-primary-default text-white',
	image: 'v1' || 'v2'
}
