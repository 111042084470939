import React from 'react'
import PropTypes from 'prop-types'

const Module = ( {header, children, ...rest }) => {
	return (
		<div {...rest}>

		{header &&
			<h4
			dangerouslySetInnerHTML={{__html: header}}
			style={{color: 'inherit'}}
			className="mb-2 mt-0"
			/>
		}

		{children}

		</div>
	)
}

Module.propTypes = {
	header: PropTypes.string,
	children: PropTypes.node.isRequired,
}

Module.defaultProps = {
	header: ``,
}

export class Sidebar extends React.Component {
	static Module = Module;

	render() {

		const {className, children} = this.props;

		const sidebarBaseClassName = 'bg-primary-darker text-white p-10 md:block hidden space-y-9 rounded-sm';
		let sidebarClassName = sidebarBaseClassName;
		
		if (className) sidebarClassName = [sidebarBaseClassName, className].join(" ");

		const sideStyle = {
		      width: '22rem',
		      minHeight: '90vh',
		      // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
		      // boxShadow: 'rgba(0, 38, 77, 0.4) -5px 5px, rgba(0, 38, 77, 0.3) -10px 10px, rgba(0, 38, 77, 0.2) -15px 15px, rgba(0, 38, 77, 0.1) -20px 20px, rgba(0, 38, 77, 0.05) -25px 25px',
			  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		      outline: '4px solid rgba(0,0,0,0.0)',
		      outlineOffset: "-4px",
		   }

		return (

			<aside style={sideStyle} className={sidebarClassName}>
				{children}
			</aside>
		)
	}
}

Sidebar.propTypes = {
	children: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([Module])
      })
    ),
}

export default Sidebar
